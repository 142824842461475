

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: none;
  background-size:cover;
  background-position: center;
  background-attachment:fixed;
  text-decoration:none;
  background-image: linear-gradient(to right top, 
    #ffffff, 
    #ffffff, 
    #eaebff, 
    #dde2ff,
    #cddaff, 
    #c2dbff, 
    #b6dcff, 
    #aaddff, 
    #a8e6ff, 
    #abefff, 
    #b1f7fe, 
    #bbfffb
      );  
}



*,
*::before,
*::after {
  box-sizing: border-box;
}

.App{  
  background-repeat:no-repeat;
  background-size:cover;
  background-attachment:fixed;
  overflow:visible;
}



.headerDiv{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100vw;
  height:100vh;
  margin:auto;
}


.webHeader {
  font-size: 5em;
  text-align: center;
  font-weight: normal;
  margin-inline:auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.servicesHeader,
.galleryHeader,
.contactHeader {
  @extend .header;
  font-weight:normal;
  text-align: center;
  border-radius: 10px;
  width: fit-content;
  margin-inline: auto;
  font-size: 4.5em;
  margin-bottom:40px;
  background-color: rgba(255, 255, 255, 0.143);
  padding: 10px;
}

.icon{
  text-align: center;
  margin-top:5px;
  text-decoration:none;
}

.icon a{
  margin-right:20px;
}

.fa-brands.fa-facebook.fa-3x, .fa-brands.fa-square-instagram.fa-3x{
  color:black;
  text-decoration:none;
}

.fa-brands.fa-facebook.fa-3x:hover, .fa-brands.fa-square-instagram.fa-3x:hover{
  color:rgb(86, 86, 86);
  text-decoration:none;
}


.cardWrap{ /*cardContainer renamed cardWrap*/
  display:flex;
  justify-content: center;
  align-items: center;
  margin-block:0px;
}

.cardContainer{ /*card renamed cardContainer*/
  width:90%;
  margin-inline:20px;
  margin-block:0px;
  display:grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns, each taking up 1 fraction of the available space */
  grid-auto-rows:minmax(.5fr,.5fr);
  gap:40px;
}

.cardDiv{ /*cardBox renamed cardDiv*/
  text-align:center;
  margin-bottom:auto;
}


.cardDiv p{
  margin-top:20px;
  padding:10px;
  border-radius:10px;
  font-size:2.5em;
  overflow-wrap: break-word;
  margin-inline:auto;
  background-color: rgba(255, 255, 255, 0.149);
}

.cardContainer img{
  max-width:100%;
  object-fit:cover;
  border-radius:10px;
  transition: ease-in-out .3s;
  margin-bottom:10px;
}

.cardContainer img:hover{
  transform:scale(.9);
  box-shadow: 10px 25px 60px rgba(0,0,0,.3);
}

.services{
  width:100vw;
  display:grid;
  place-items:center;
  margin-block:10vh
}

.servicesHeader{
  margin:0px;
}

.servicesDesc{
  text-align:center;
  font-size:1.2em;
}

.gallery{
  width:100vw;
  display: flex;
  overflow-x: scroll;
}

.gallery div{
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
  padding: 10px;
  flex: none;
}

.gallery div img {
  width: 100%;
  transition: transform 0.5s;
  border-radius:10px;
}

.gallery::-webkit-scrollbar {
  display: none;
}

.gallery-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  margin:auto;
  margin-bottom:10vh;
}

#back, #next {
  width: 6vw;
  cursor: pointer;
  margin: 2vw;
  transition:.5s ease;
}

#back:hover, #next:hover{
  transform: scale(1.1);
}

.gallery div img:hover{
 cursor: pointer;
 transform: scale(.8);
}

.contact{
  height:70vh;
  width:100vw;
}

.formContainer{
  display:flex;
  justify-content: center;
  align-items:center;
  flex-direction:column;
}

form{
  display:flex;
  justify-content:center;
  align-items:center;
  gap:40px;
  flex-direction:column;
  width:90%;
  margin:auto;
}

form input, form textarea{
  background-color: rgba(255, 255, 255, 0.356);
  color:black;
  position:relative;
  width:70%;
  height:5vh;
  border-radius:10px;
  border:none;
  padding-left:10px;
  outline:none;
  font-size:1.5em;
  resize:none;
}

form textarea{
  height:fit-content;
}

form button{
  background-color: rgba(255, 255, 255, 0.186);
  color:black;
  font-weight:normal;
  cursor:pointer;
  border:none;
  width:fit-content;
  font-size:1.8em;
  border-radius:10px;
  transition: ease-in-out .3s;
  padding:10px;
}

#name,#email,#phone,#message{
  z-index:1000;
}

#name:hover,#email:hover,#phone:hover,#message:hover,#submit:hover{
  background-color:rgb(50, 149, 236, .3);
}
::placeholder{
  color:black;
  opacity:.7;
  font-family:arial;
}

.footer{
  width:100%;
  display:flex;
  justify-content: center;
  align-items: start;
  gap:40px;
  margin-top:10vh;
  margin-bottom:20px;
}



  @media (max-width: 1200px ) {
      .cardContainer{
          grid-template-columns: repeat(2, 1fr); /* Two columns */
          width:70%;
      }
      .desc{
          margin-bottom:20vh;
      }
      
      
     
  }
  
  @media (max-width: 768px) {
      .cardContainer{
          grid-template-columns: 1fr; /* One column */
          width:60%;
      }

      

      ::placeholder{
        font-size:1em;
      }
      
      
  }

  @media (max-width:600px){
    ::placeholder{
      font-size:.8em;
    }
    

    .cardDiv p{
      font-size:1.5em;
    }

    .galleryContainer{ 
      display:none;
    }
    
  }

  @media (max-width:400px){
    

    .cardDiv p{
      font-size:2em;
    }
  }



